import Marquee from "react-fast-marquee";
import "./style.css";
import b1Image from "@/src/assets/images/aboutUs/brands/b1.png";
import b2Image from "@/src/assets/images/aboutUs/brands/b2.png";
import b3Image from "@/src/assets/images/aboutUs/brands/b3.png";
import b4Image from "@/src/assets/images/aboutUs/brands/b4.png";
import b5Image from "@/src/assets/images/aboutUs/brands/b5.png";
import b6Image from "@/src/assets/images/aboutUs/brands/b6.png";
import b7Image from "@/src/assets/images/aboutUs/brands/b7.png";
import b8Image from "@/src/assets/images/aboutUs/brands/b8.png";
import b9Image from "@/src/assets/images/aboutUs/brands/b9.png";
import b10Image from "@/src/assets/images/aboutUs/brands/b10.png";
import b11Image from "@/src/assets/images/aboutUs/brands/b11.png";
import b12Image from "@/src/assets/images/aboutUs/brands/b12.png";
import b13Image from "@/src/assets/images/aboutUs/brands/b13.png";
import b14Image from "@/src/assets/images/aboutUs/brands/b14.png";
import b15Image from "@/src/assets/images/aboutUs/brands/b15.png";
import b16Image from "@/src/assets/images/aboutUs/brands/b16.png";
import b17Image from "@/src/assets/images/aboutUs/brands/b17.png";
import b18Image from "@/src/assets/images/aboutUs/brands/b18.png";
import b19Image from "@/src/assets/images/aboutUs/brands/b19.png";
import b20Image from "@/src/assets/images/aboutUs/brands/b20.png";
import b21Image from "@/src/assets/images/aboutUs/brands/b21.png";

import Image from "next/image";

function BrandsCard({ className }) {
	return (
		<div className={`brands_card ${className}`}>
			<Marquee speed={200}>
				<Image
					className="brand_img"
					src={b1Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b2Image}
					width={500}
					height={0}
					alt="icon"
				/>

				<Image
					className="brand_img"
					src={b3Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b4Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b5Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b6Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b7Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b8Image}
					width={300}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b9Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b10Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b11Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b12Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b13Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b14Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b15Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b16Image}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b17Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b18Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b19Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b20Image}
					width={500}
					height={0}
					alt="icon"
				/>
				<Image
					className="brand_img"
					src={b21Image}
					width={500}
					height={0}
					alt="icon"
				/>
			</Marquee>
		</div>
	);
}

export default BrandsCard;
