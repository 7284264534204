import React, { useEffect, useMemo, useRef, useState } from "react";
import "./style.css";
import * as d3 from "d3";
import arc_1 from "@/src/assets/images/homepage/why_choose_us/arc_1.svg";
import arc_2 from "@/src/assets/images/homepage/why_choose_us/arc_2.svg";
import arc_3 from "@/src/assets/images/homepage/why_choose_us/arc_3.svg";
import arc_4 from "@/src/assets/images/homepage/why_choose_us/arc_4.svg";
import arc_5 from "@/src/assets/images/homepage/why_choose_us/arc_5.svg";
import arc_1_white from "@/src/assets/images/homepage/why_choose_us/arc_1_white.svg";
import arc_2_white from "@/src/assets/images/homepage/why_choose_us/arc_2_white.svg";
import arc_3_white from "@/src/assets/images/homepage/why_choose_us/arc_3_white.svg";
import arc_4_white from "@/src/assets/images/homepage/why_choose_us/arc_4_white.svg";
import arc_5_white from "@/src/assets/images/homepage/why_choose_us/arc_5_white.svg";
import { useMediaQuery } from "react-responsive";

let smallDeskMedia;
const arcIconsGradient = [arc_1, arc_5, arc_4, arc_3, arc_2];
const arcIconsWhite = [
	arc_1_white,
	arc_5_white,
	arc_4_white,
	arc_3_white,
	arc_2_white,
];

const arcTexts = [
	"Zero Downtime",
	"Delivery Tracking",
	"Client Support",
	"Zero Asset Cost",
	"Charging Station",
];

const getRotateLen = (i) => {
	return i === -1 ? 0 : (smallDeskMedia ? 2 : 3) - i;
};

const WhyChooseUsChart = ({ width, height, onArcClick }) => {
	smallDeskMedia = useMediaQuery({ query: "(max-width: 768px)" });
	const ref = useRef(null);
	const data = [
		{ value: 1 },
		{ value: 1 },
		{ value: 1 },
		{ value: 1 },
		{ value: 1 },
	];
	const [selectedArc, setSelectedArc] = useState(0);
	const [arcRotation, setArcRotation] = useState(0);
	// const [currentDegree, setCurrentDegree] = useState(0);
	const outerRadius = 200;
	const innerRadius = 135;
	const gapAngle = 0.02;

	// function positiveModulo(n, m) {
	// 	return ((n % m) + m) % m;
	// }

	function getClockwiseDegree(targetDegree) {
		// let distanceToTarget = targetDegree - currentDegree;
		// if (targetDegree < currentDegree) {
		// 	distanceToTarget += 360;
		// }
		// let newDegree = currentDegree + distanceToTarget;
		// console.log(targetDegree, currentDegree, distanceToTarget, newDegree);
		// setCurrentDegree(newDegree);
		// return newDegree;
		return targetDegree;
	}

	useEffect(() => {
		const perArxLen = 360 / arcTexts?.length;
		setArcRotation(
			getClockwiseDegree(perArxLen * getRotateLen(selectedArc)) +
				(smallDeskMedia ? 0 : perArxLen * 0.25),
		);
	}, [selectedArc]);

	const pie = useMemo(() => {
		const pieGenerator = d3.pie().value((d) => d.value);
		return pieGenerator(data);
	}, data);

	const arcGenerator = d3.arc();

	const shapes = pie.map((grp, i) => {
		const startAngle = grp.startAngle + gapAngle;
		const endAngle = grp.endAngle - gapAngle;
		const textPathRadius = outerRadius + 20;
		const textArc = d3
			.arc()
			.innerRadius(textPathRadius)
			.outerRadius(textPathRadius)
			.startAngle(startAngle)
			.endAngle(endAngle);

		const sliceInfo = {
			innerRadius,
			outerRadius,
			startAngle,
			endAngle,
		};
		const sliceInfoStroke = {
			innerRadius: innerRadius - 7,
			outerRadius: outerRadius + 7,
			startAngle,
			endAngle,
		};
		const centroid = arcGenerator.centroid(sliceInfo);
		const slicePath = arcGenerator(sliceInfo);
		const slicePathStroke = arcGenerator(sliceInfoStroke);
		const iconWidth = 40;
		const iconHeight = 40;
		return (
			<g
				key={i}
				className={`slice ${selectedArc === i ? "selected" : ""}`}
			>
				<g key={`textPathGroup-${i}`}>
					<path
						id={`textPath-${i}`}
						d={textArc()}
						fill="none"
						stroke="none"
					/>
				</g>
				<g key={`textGroup-${i}`}>
					<text dy="5" textAnchor={"center"}>
						<textPath
							href={`#textPath-${i}`}
							className="circle_text"
							startOffset="13%"
						>
							{arcTexts[i]}
						</textPath>
					</text>
				</g>
				<g
					onClick={() => {
						if (typeof onArcClick !== "undefined") onArcClick(i);
						setSelectedArc(i);
					}}
				>
					<defs>
						<linearGradient
							id="gradient"
							x1="0%"
							y1="0%"
							x2="100%"
							y2="100%"
						>
							<stop
								offset="0%"
								style={{
									stopColor: "rgba(0, 146, 189, 0.40)",
									stopOpacity: "1",
								}}
							/>
							<stop
								offset="100%"
								style={{
									stopColor: "rgba(0, 198, 122, 0.40)",
									stopOpacity: "1",
								}}
							/>
						</linearGradient>
					</defs>
					<defs>
						<linearGradient
							id="gradientHovered"
							x1="0.86"
							y1="0.14"
							x2="0.14"
							y2="0.86"
						>
							<stop offset="24.38%" stopColor="#07DD92" />
							<stop offset="75.69%" stopColor="#06B9F0" />
						</linearGradient>
					</defs>
					<path
						className="on_hover"
						d={slicePathStroke}
						stroke="rgba(6, 185, 240, 0.20)"
						strokeWidth="15"
						fill="none"
					/>
					<path
						className="stroke"
						d={slicePath}
						stroke="#06B9F0"
						strokeWidth="1.5"
						fill="url(#gradient)"
					/>
					<image
						href={
							selectedArc === i
								? arcIconsWhite[i].src
								: arcIconsGradient[i].src
						}
						x={centroid[0] - iconWidth / 2}
						y={centroid[1] - iconHeight / 2}
						width={iconWidth}
						height={iconHeight}
					/>
				</g>
			</g>
		);
	});
	const [timerActive, setTimerActive] = useState(true);
	const intervalRef = useRef(null);

	const startTimer = (force = false) => {
		if (!timerActive && !force) return;
		setTimerActive(true);

		intervalRef.current = setInterval(() => {
			if (!timerActive) {
				clearInterval(intervalRef.current);
				return;
			}
			if (typeof onArcClick !== "undefined")
				onArcClick((selectedArc + 1) % 5);
			setSelectedArc((selectedArc + 1) % 5);
		}, 3000);
	};

	const stopTimer = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
		setTimerActive(false);
	};

	useEffect(() => {
		if (timerActive) {
			startTimer();
		}

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, [selectedArc, timerActive]);
	return (
		<div id="why_choose_us_chart">
			<svg
				width={width}
				height={height}
				style={{
					display: "inline-block",
					transition: "all 1s",
					transform: `rotate(${arcRotation}deg)`,
				}}
			>
				<g
					transform={`translate(${width}, ${height})`}
					className={"why_choose_us_chart_container"}
					ref={ref}
					onMouseEnter={stopTimer}
					onMouseLeave={() => {
						startTimer(true);
					}}
				>
					{shapes}
				</g>
			</svg>
		</div>
	);
};

export default WhyChooseUsChart;
