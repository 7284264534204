import "./style.css";
import bg_1 from "@/src/assets/images/homepage/bg_1.webp";
import bg_2 from "@/src/assets/images/homepage/bg_2.svg";
import CustomHeader from "../header/header";
import { useEffect, useState } from "react";
import CountUp from "react-countup";

export default function HomepageSection1() {
	const [heroTitleFront, setHeroTitleFront] = useState(1);
	const [heroTitleBack, setHeroTitleBack] = useState(0);
	const [heroTopVideoPosition, setHeroTopVideoPosition] =
		useState("absolute");
	const [bg2Unique, setbg2Unique] = useState();
	const [loaded, setLoaded] = useState(false);
	const [valueMultiplier, setValueMultiplier] = useState(1);
	const [bg2Dim, setBg2Dim] = useState({ width: 0, height: 0 });
	const [windowDimensions, setWindowDimensions] = useState({});

	useEffect(() => {
		const daysLeft = getDaysLeft();
		setValueMultiplier(daysLeft);
		setbg2Unique(new Date().getTime());
		setLoaded(true);
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [bg2Dim]);

	const handleResize = () => {
		setWindowDimensions(getWindowDimensions());
	};

	const getWindowDimensions = () => {
		if (typeof window !== "undefined") {
			const { innerWidth: width, innerHeight: height } = window;
			return {
				width,
				height,
			};
		}
		return {};
	};

	const handleScrollBG = () => {
		const scrollPosition = window.scrollY;
		let bg_2 = document.querySelector(".bg_2");
		let scaleTransform = Math.max(scrollPosition / 20, 1);
		bg_2.style.height = `${bg2Dim.height * scaleTransform}px`;
		bg_2.style.width = `${bg2Dim.width * scaleTransform}px`;
		bg_2.style.transform = `scale(${scaleTransform},${scaleTransform})`;
	};
	const handleScrollTranslate = () => {
		const scrollPosition = window.scrollY;
		const screenHeight = windowDimensions.height;
		const topPosVideo =
			scrollPosition >= screenHeight &&
			scrollPosition < screenHeight * 2 + 400
				? "fixed"
				: "absolute";
		setHeroTopVideoPosition(topPosVideo);
	};
	const handleScrollTitle = () => {
		let scrollPosition = window.scrollY;
		if (scrollPosition >= 90 && scrollPosition <= 100) {
			scrollPosition = scrollPosition > 100 ? 10 : scrollPosition / 10;
			setHeroTitleFront(10 - scrollPosition);
			setHeroTitleBack(0);
		} else if (scrollPosition > 100) {
			scrollPosition = scrollPosition > 110 ? 10 : scrollPosition - 100;
			setHeroTitleFront(0);
			setHeroTitleBack(scrollPosition / 10);
		} else {
			setHeroTitleFront(1);
			setHeroTitleBack(0);
		}
	};

	const handleScroll = () => {
		handleScrollBG();
		handleScrollTranslate();
		handleScrollTitle();
	};

	const getDaysLeft = () => {
		const targetDate = "2024-05-09"; // Target date in YYYY-MM-DD format (today)
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, "0");
		const day = String(now.getDate()).padStart(2, "0");
		const currentDate = `${year}-${month}-${day}`;
		const target = new Date(targetDate);

		const current = new Date(currentDate);

		const differenceMs = current - target;

		const daysLeft = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

		return daysLeft;
	};
	const handleImageLoad = (event) => {
		setBg2Dim({
			width: event.target.offsetWidth,
			height: event.target.offsetHeight,
		});
	};
	return (
		<div className="section_1_homepage">
			<CustomHeader />
			<div className="bg">
				<div className="bg1_div">
					<img src={bg_1.src} alt="bg_1" className="bg_1" />
				</div>
				<div className="bg2_div">
					<img
						src={`${bg_2.src}?${bg2Unique}`}
						alt="bg_2"
						className="bg_2"
						onLoad={handleImageLoad}
					/>
					<div></div>
				</div>
			</div>
			<div className="bgvideo">
				<div
					className="video"
					style={{
						position: heroTopVideoPosition,
						top: heroTopVideoPosition === "fixed" ? "-100vh" : "0",
					}}
				>
					<video autoPlay muted loop>
						<source
							src="/video/homepage-hero.webm"
							type="video/webm"
						/>
					</video>
				</div>
			</div>
			<div className="title_parent">
				<div className="title">
					<div
						className="title_div"
						style={{ opacity: heroTitleFront, zIndex: 10 }}
					>
						<p className="title1">More than just transportation,</p>
						<p className="title2">a complete EV ecosystem</p>
						<div
							className="btn"
							onClick={() => {
								router.push("/contact-us");
							}}
						>
							<span>Contact Us</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
							>
								<path
									d="M11 11.5V5.5H13V11.5H19V13.5H13V19.5H11V13.5H5V11.5H11Z"
									fill="black"
								/>
							</svg>
						</div>
					</div>
					<div
						className="title_div"
						style={{ opacity: heroTitleBack }}
					>
						<p className="title3">
							As India races towards an electrified commercial
							vehicle future by 2030, We at ZEVO are committed to
							successfully accelerate the EV transition of your
							business.
						</p>
					</div>
				</div>
			</div>
			<div className="section_1_footer">
				<div className="section_1_footer_div">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="58"
						height="70"
						viewBox="0 0 58 70"
						fill="none"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M42.0293 16.955C44.9362 20.087 46.5812 24.4292 46.5812 28.9697C46.5812 33.5102 44.9362 37.8524 42.0293 40.9845L31.0348 52.9993L20.0402 40.9845C17.1334 37.8524 15.4883 33.5102 15.4883 28.9697C15.4883 24.4292 17.1334 20.087 20.0402 16.955C26.1948 10.3171 35.8747 10.3171 42.0293 16.955Z"
							fill="white"
							stroke="white"
							strokeWidth="5.9375"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M38.0541 27.8394C38.0291 29.6691 37.2889 31.4138 35.9963 32.6895C34.7037 33.9653 32.9647 34.6676 31.1618 34.6419C29.3589 34.6676 27.6199 33.9653 26.3273 32.6895C25.0347 31.4138 24.2945 29.6691 24.2695 27.8394C24.3231 24.0303 27.4086 20.9863 31.1618 21.0398C34.915 20.9863 38.0005 24.0303 38.0541 27.8394Z"
							fill="#152A31"
						/>
					</svg>
					<span className="section_1_footer_title">
						Kms Travelled
					</span>
					<CountUp
						start={loaded ? 0 : -1}
						end={4075312 + 25000 * valueMultiplier}
						duration={2}
						useIndianSeparators={true}
						className="section_1_footer_count"
						enableScrollSpy={loaded}
						scrollSpyOnce={true}
					/>
				</div>
				<div className="section_1_footer_div">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="49"
						height="34"
						viewBox="0 0 49 34"
						fill="none"
					>
						<path
							d="M3 4.85714C3 4.06078 3 3.6626 3.05652 3.32997C3.34126 1.65411 4.65411 0.341258 6.32997 0.0565162C6.6626 0 7.06078 0 7.85714 0H41.1429C41.9392 0 42.3374 0 42.67 0.0565162C44.3459 0.341258 45.6587 1.65411 45.9435 3.32997C46 3.6626 46 4.06078 46 4.85714C46 5.25532 46 5.45441 45.9717 5.62073C45.8294 6.45866 45.1729 7.11509 44.335 7.25746C44.1687 7.28571 43.9696 7.28571 43.5714 7.28571H5.42857C5.03039 7.28571 4.8313 7.28571 4.66499 7.25746C3.82705 7.11509 3.17063 6.45866 3.02826 5.62073C3 5.45441 3 5.25532 3 4.85714Z"
							fill="white"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.94237 11.7588C4.89844 11.8027 4.89844 11.8734 4.89844 12.0148V26.0006C4.89844 29.7718 4.89844 31.6574 6.07001 32.829C7.24158 34.0006 9.1272 34.0006 12.8984 34.0006H36.0984C39.8697 34.0006 41.7553 34.0006 42.9269 32.829C44.0984 31.6574 44.0984 29.7718 44.0984 26.0006V12.0148C44.0984 11.8734 44.0984 11.8027 44.0545 11.7588C44.0106 11.7148 43.9399 11.7148 43.7984 11.7148H5.19844C5.05702 11.7148 4.98631 11.7148 4.94237 11.7588ZM18.5 17C17.6716 17 17 17.6716 17 18.5C17 19.3284 17.6716 20 18.5 20H30.5C31.3284 20 32 19.3284 32 18.5C32 17.6716 31.3284 17 30.5 17H18.5Z"
							fill="white"
						/>
					</svg>
					<span className="section_1_footer_title">
						Clean Deliveries
					</span>
					<CountUp
						start={loaded ? 0 : -1}
						end={1652265 + 10000 * valueMultiplier}
						duration={2}
						useIndianSeparators={true}
						className="section_1_footer_count"
						enableScrollSpy={loaded}
						scrollSpyOnce={true}
					/>
				</div>
				<div className="section_1_footer_div">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="48"
						height="48"
						viewBox="0 0 48 48"
						fill="none"
					>
						<path
							d="M2 24.0013C2 26.8028 2.5518 29.5769 3.6239 32.1652C4.696 34.7535 6.26741 37.1053 8.24839 39.0862C10.2294 41.0672 12.5811 42.6386 15.1694 43.7107C17.7577 44.7828 20.5318 45.3346 23.3333 45.3346C26.1349 45.3346 28.909 44.7828 31.4972 43.7107C34.0855 42.6386 36.4373 41.0672 38.4183 39.0862C40.3993 37.1053 41.9707 34.7535 43.0428 32.1652C44.1149 29.5769 44.6667 26.8028 44.6667 24.0013C44.6667 21.1998 44.1149 18.4257 43.0428 15.8374C41.9707 13.2491 40.3993 10.8973 38.4183 8.91636C36.4373 6.93537 34.0855 5.36397 31.4972 4.29187C28.909 3.21977 26.1349 2.66797 23.3333 2.66797C20.5318 2.66797 17.7577 3.21977 15.1694 4.29187C12.5811 5.36397 10.2294 6.93537 8.24839 8.91636C6.26741 10.8973 4.696 13.2491 3.6239 15.8374C2.5518 18.4257 2 21.1998 2 24.0013Z"
							stroke="white"
							strokeWidth="4"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M18.5938 24.0025C18.5938 25.2598 19.0932 26.4656 19.9823 27.3547C20.8713 28.2437 22.0772 28.7432 23.3345 28.7432M18.5938 24.0025C18.5938 22.7451 19.0932 21.5393 19.9823 20.6503C20.8713 19.7612 22.0772 19.2617 23.3345 19.2617C24.5918 19.2617 25.7976 19.7612 26.6867 20.6503C27.5758 21.5393 28.0752 22.7451 28.0752 24.0025M18.5938 24.0025L2.59375 19.2617M23.3345 28.7432C24.5918 28.7432 25.7976 28.2437 26.6867 27.3547C27.5758 26.4656 28.0752 25.2598 28.0752 24.0025M23.3345 28.7432V45.3358M28.0752 24.0025L44.0752 19.2617"
							stroke="white"
							strokeWidth="4"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					<span className="section_1_footer_title">
						Driver Partners
					</span>
					<CountUp
						start={loaded ? 0 : -1}
						end={1210 + 5 * valueMultiplier}
						duration={2}
						useIndianSeparators={true}
						className="section_1_footer_count"
						enableScrollSpy={loaded}
						scrollSpyOnce={true}
					/>
				</div>
			</div>
		</div>
	);
}
