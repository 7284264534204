"use client";
import "./style.css";
import logo from "@/src/assets/images/logo.webp";
import CustomHeader from "../components/header/header";
import bg_tri from "@/src/assets/images/homepage/Tri_bg.webp";
import bg_shape from "@/src/assets/images/homepage/shape.webp";
import hyperpure from "@/src/assets/images/homepage/case-study/hyperpure.webp";
import hyperpure_light from "@/src/assets/images/homepage/case-study/hyperpure-light.webp";
import bigbasket from "@/src/assets/images/homepage/case-study/bigbasket.webp";
import bigbasket_light from "@/src/assets/images/homepage/case-study/bigbasket-light.webp";
import flipkart from "@/src/assets/images/homepage/case-study/flipkart.webp";
import flipkart_light from "@/src/assets/images/homepage/case-study/flipkart-light.webp";
import shadowfox from "@/src/assets/images/homepage/case-study/shadowfox.webp";
import shadowfox_light from "@/src/assets/images/homepage/case-study/shadowfox-light.webp";
import holisol from "@/src/assets/images/homepage/case-study/holisol.webp";
import holisol_light from "@/src/assets/images/homepage/case-study/holisol-light.webp";
import porter from "@/src/assets/images/homepage/case-study/porter.webp";
import porter_light from "@/src/assets/images/homepage/case-study/porter-light.webp";
import wwo1 from "@/src/assets/images/homepage/wwo/wwo1.webp";
import wwo2 from "@/src/assets/images/homepage/wwo/wwo2.webp";
import wwo3 from "@/src/assets/images/homepage/wwo/wwo3.webp";
import wwo4 from "@/src/assets/images/homepage/wwo/wwo4.webp";
import wwo5 from "@/src/assets/images/homepage/wwo/wwo5.webp";
import ceo from "@/src/assets/images/homepage/ceo.webp";
import coo from "@/src/assets/images/homepage/coo.webp";
import article1 from "@/src/assets/images/homepage/articles/article1.webp";
import article2 from "@/src/assets/images/homepage/articles/article2.webp";
import article3 from "@/src/assets/images/homepage/articles/article3.webp";
import article4 from "@/src/assets/images/homepage/articles/article4.webp";
import article5 from "@/src/assets/images/homepage/articles/article5.webp";
import article6 from "@/src/assets/images/homepage/articles/article6.webp";
import article7 from "@/src/assets/images/homepage/articles/article7.webp";
import article8 from "@/src/assets/images/homepage/articles/article8.webp";
import associate1 from "@/src/assets/images/homepage/associates/associate1.webp";
import associate2 from "@/src/assets/images/homepage/associates/associate2.webp";
import associate3 from "@/src/assets/images/homepage/associates/associate3.webp";
import mobileHeroImage from "@/src/assets/images/homepage/heeroImg.webp";
import CaseStudy from "../components/dashboard/case_study/case_study";
import WhatWeOffer from "../components/dashboard/what_we_offer/what_we_offer";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import FeaturedArticle from "../components/dashboard/featured_article/featured_article";
import Footer from "../components/footer/Footer";
import { Autoplay, EffectCards } from "swiper/modules";
import SwiperCore from "swiper";
import { InView } from "react-intersection-observer";
import CountUp from "react-countup";
import WhyChooseUsChart from "../components/dashboard/why_choose_us_chart/why_choose_us_chart";
import Image from "next/image";
import { isMobile, isTablet } from "react-device-detect";
import playBtn from "@/src/assets/images/aboutUs/section2/playBtn.svg";
import { useRouter } from "next/navigation";
import Link from "next/link";
import BrandsCard from "../components/BrandsCard/BrandsCard";
import HomepageSection1 from "../components/HomepageSection1/HomepageSection1";

function getWindowDimensions() {
	if (typeof window !== "undefined") {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	}
	return {};
}

export default function Home() {
	const router = useRouter();
	const [loaded, setLoaded] = useState(false);
	useEffect(() => {
		setLoaded(true);
	}, []);
	const [swiper, setSwiper] = useState(null);
	const [stretchValue, setStretchValue] = useState(2000);
	const [associateActiveSlide, setAssociateActiveSlide] = useState(0);
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions(),
	);
	const featuredArticleRef = useRef(null);
	const [url, setVideoUrl] = useState("/video/zevo_map.webm");
	const videoRef = useRef();
	const [valueMultiplier, setValueMultiplier] = useState(1);
	const [valueMultiplier1, setValueMultiplier1] = useState(1);
	useEffect(() => {
		videoRef.current?.load();
	}, [url]);

	useEffect(() => {
		setVideoUrl(
			windowDimensions?.width <= 425
				? "/video/zevo_map_mobile.webm"
				: "/video/zevo_map.webm",
		);
	}, [windowDimensions]);

	const [isFeaturedArticleBeginning, setIsFeaturedArticleBeginning] =
		useState(true);
	const [isFeaturedArticleEnd, setIsFeaturedArticleEnd] = useState(false);
	const onFeaturedArticleSlideChange = () => {
		if (featuredArticleRef.current) {
			const swiperInstance = featuredArticleRef.current.swiper;
			setIsFeaturedArticleBeginning(swiperInstance.isBeginning);
			setIsFeaturedArticleEnd(swiperInstance.isEnd);
		}
	};

	const getStretchValue = () => {
		if (typeof window !== "undefined") {
			if (window.innerWidth >= 1024) {
				return 7550;
			} else if (window.innerWidth >= 768) {
				return 5200;
			}
		}
		return 2000;
	};

	useEffect(() => {
		const handleResize = () => {
			setStretchValue(getStretchValue());
			setWindowDimensions(getWindowDimensions());
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [swiper]);

	useEffect(() => {
		let height = 0;
		document
			.querySelectorAll(".associate_swiper_cards .swiper-slide")
			.forEach((a) => {
				height = Math.max(height, a.offsetHeight);
			});
		document
			.querySelectorAll(".associate_swiper_cards .swiper-slide")
			.forEach((a) => {
				a.style.height = `${height}px`;
			});
	}, []);

	SwiperCore.use([EffectCards, Autoplay]);

	function getDaysLeft() {
		const targetDate = "2024-05-09"; // Target date in YYYY-MM-DD format (today)
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
		const day = String(now.getDate()).padStart(2, "0");
		const currentDate = `${year}-${month}-${day}`;
		// Parse the target date string into a Date object
		const target = new Date(targetDate);

		const current = new Date(currentDate);

		const differenceMs = current - target;

		const daysLeft = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

		return daysLeft;
	}
	function getDaysLeft1() {
		const targetDate = "2024-05-09"; // Target date in YYYY-MM-DD format (today)
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
		const day = String(now.getDate()).padStart(2, "0");
		const currentDate = `${year}-${month}-${day}`;
		// Parse the target date string into a Date object
		const target = new Date(targetDate);

		const current = new Date(currentDate);

		const differenceMs = current - target;

		const daysLeft = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

		return daysLeft;
	}
	useEffect(() => {
		const daysLeft = getDaysLeft();
		const daysLeft1 = getDaysLeft1();
		setValueMultiplier(daysLeft);
		setValueMultiplier1(daysLeft1);
	}, []);

	const [selectedWhyChooseUs, setSelectedWhyChooseUs] = useState(0);

	const whyChooseUsTexts = [
		{
			title: "Zero Downtime",
			desc: "Data-driven insights and cutting-edge technology for enhanced uptime, reliability and smoother logistics operations.",
		},
		{
			title: "Real-time Delivery Tracking",
			desc: "Intelligent monitoring systems to track deliveries to ensure the promises you have made to your end customer.",
		},
		{
			title: "Dedicated Client Support",
			desc: "Robust infrastructure and dedicated team to handle any delivery surge with ease.",
		},
		{
			title: "Zero Asset Cost",
			desc: "Innovative pay-per-use model to maximize efficiency and minimize cost.",
		},
		{
			title: "Battery Charging Stations",
			desc: "Network of strategically placed fast-charging stations and battery swap bays to minimize downtime and maintain peak efficiency.",
		},
	];
	const videoRefSeeUsAction = useRef(null);
	const videoPlayBtnRef = useRef(null);

	const restartVideo = () => {
		const video = videoRefSeeUsAction.current;
		if (video) {
			video.currentTime = 0;
			videoPlayBtnRef.current.style.display = "block";
		}
	};

	const toggleVideo = () => {
		const video = videoRefSeeUsAction.current;

		if (video.paused) {
			video.play();
			videoPlayBtnRef.current.style.display = "none";
		} else {
			video.pause();
			videoPlayBtnRef.current.style.display = "block";
		}
	};
	const [script, setScript] = useState();
	useEffect(() => {
		setScript(
			JSON.stringify({
				"@context": "https://schema.org",
				"@type": "WebSite",
				url: "https://zevoindia.in/",
				name: "Zevo India",
				description:
					"ZEVO India, the electric mobility powerhouse, offers eco-friendly delivery with a diverse fleet. Discover our commitment to zero emissions.",
				mainEntity: {
					"@type": "Organization",
					name: "Zevo India",
					legalName: "ZEVO India",
					logo: {
						"@type": "ImageObject",
						url: logo.src,
					},
					address: [
						{
							"@type": "PostalAddress",
							addressType: "Registered Address",
							streetAddress: "C-10, Lower Ground Floor",
							addressLocality: "South Extension-2",
							postalCode: "110049",
							addressRegion: "New Delhi",
							addressCountry: "IN",
						},
						{
							"@type": "PostalAddress",
							addressType: "Head Office",
							streetAddress: "601, 602, 603, Sixth Floor",
							addressLocality:
								"DLF South Court Mall, Saket District Centre",
							postalCode: "110017",
							addressRegion: "New Delhi",
							addressCountry: "IN",
						},
					],
					telephone: ["+91-8826404013", "+91-9990204000"],
					email: ["office@zevoindia.in", "operations@zevoindia.in"],
					sameAs: [
						"https://in.linkedin.com/company/zevoindia",
						"https://www.instagram.com/zevoindia/",
					],
					slogan: "ZEVO India: India's Electric Mobility Powerhouse",
				},
			}),
		);
	});
	return (
		<>
			<script type="application/ld+json">{script}</script>
			<div className="homepage">
				<HomepageSection1 />
				<div className="section1_mobile">
					<CustomHeader design_2={true} />
					<div className="hero_container">
						<div className="title_container">
							<h2 className="title1">
								More than just transportation,
							</h2>
							<h1 className="title2">a complete EV ecosystem</h1>
							<div
								className="btn"
								onClick={() => {
									router.push("/contact-us");
								}}
							>
								<span>Contact Us</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
								>
									<path
										d="M11 11.5V5.5H13V11.5H19V13.5H13V19.5H11V13.5H5V11.5H11Z"
										fill="black"
									/>
								</svg>
							</div>
						</div>
						<Image
							className="mobile_hero_image"
							width={800}
							height={0}
							src={mobileHeroImage}
							alt="hero-image"
							loading="lazy"
						/>
						<div className="section_1_mobile_footer">
							<div className="section_1_footer_div">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="58"
									height="70"
									viewBox="0 0 58 70"
									fill="none"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M42.0293 16.955C44.9362 20.087 46.5812 24.4292 46.5812 28.9697C46.5812 33.5102 44.9362 37.8524 42.0293 40.9845L31.0348 52.9993L20.0402 40.9845C17.1334 37.8524 15.4883 33.5102 15.4883 28.9697C15.4883 24.4292 17.1334 20.087 20.0402 16.955C26.1948 10.3171 35.8747 10.3171 42.0293 16.955Z"
										fill="white"
										stroke="white"
										strokeWidth="5.9375"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M38.0541 27.8394C38.0291 29.6691 37.2889 31.4138 35.9963 32.6895C34.7037 33.9653 32.9647 34.6676 31.1618 34.6419C29.3589 34.6676 27.6199 33.9653 26.3273 32.6895C25.0347 31.4138 24.2945 29.6691 24.2695 27.8394C24.3231 24.0303 27.4086 20.9863 31.1618 21.0398C34.915 20.9863 38.0005 24.0303 38.0541 27.8394Z"
										fill="#152A31"
									/>
								</svg>
								<span className="section_1_footer_title">
									Kms Travelled
								</span>
								<CountUp
									start={loaded ? 0 : -1}
									end={4075312 + 25000 * valueMultiplier}
									duration={2}
									useIndianSeparators={true}
									className="section_1_footer_count"
									enableScrollSpy={loaded}
									scrollSpyOnce={true}
								/>
							</div>
							<div className="section_1_footer_div">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="49"
									height="34"
									viewBox="0 0 49 34"
									fill="none"
								>
									<path
										d="M3 4.85714C3 4.06078 3 3.6626 3.05652 3.32997C3.34126 1.65411 4.65411 0.341258 6.32997 0.0565162C6.6626 0 7.06078 0 7.85714 0H41.1429C41.9392 0 42.3374 0 42.67 0.0565162C44.3459 0.341258 45.6587 1.65411 45.9435 3.32997C46 3.6626 46 4.06078 46 4.85714C46 5.25532 46 5.45441 45.9717 5.62073C45.8294 6.45866 45.1729 7.11509 44.335 7.25746C44.1687 7.28571 43.9696 7.28571 43.5714 7.28571H5.42857C5.03039 7.28571 4.8313 7.28571 4.66499 7.25746C3.82705 7.11509 3.17063 6.45866 3.02826 5.62073C3 5.45441 3 5.25532 3 4.85714Z"
										fill="white"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M4.94237 11.7588C4.89844 11.8027 4.89844 11.8734 4.89844 12.0148V26.0006C4.89844 29.7718 4.89844 31.6574 6.07001 32.829C7.24158 34.0006 9.1272 34.0006 12.8984 34.0006H36.0984C39.8697 34.0006 41.7553 34.0006 42.9269 32.829C44.0984 31.6574 44.0984 29.7718 44.0984 26.0006V12.0148C44.0984 11.8734 44.0984 11.8027 44.0545 11.7588C44.0106 11.7148 43.9399 11.7148 43.7984 11.7148H5.19844C5.05702 11.7148 4.98631 11.7148 4.94237 11.7588ZM18.5 17C17.6716 17 17 17.6716 17 18.5C17 19.3284 17.6716 20 18.5 20H30.5C31.3284 20 32 19.3284 32 18.5C32 17.6716 31.3284 17 30.5 17H18.5Z"
										fill="white"
									/>
								</svg>
								<span className="section_1_footer_title">
									Clean Deliveries
								</span>
								<CountUp
									start={loaded ? 0 : -1}
									end={1652265 + 10000 * valueMultiplier}
									duration={2}
									useIndianSeparators={true}
									className="section_1_footer_count"
									enableScrollSpy={loaded}
									scrollSpyOnce={true}
								/>
							</div>
							<div className="section_1_footer_div">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="48"
									height="48"
									viewBox="0 0 48 48"
									fill="none"
								>
									<path
										d="M2 24.0013C2 26.8028 2.5518 29.5769 3.6239 32.1652C4.696 34.7535 6.26741 37.1053 8.24839 39.0862C10.2294 41.0672 12.5811 42.6386 15.1694 43.7107C17.7577 44.7828 20.5318 45.3346 23.3333 45.3346C26.1349 45.3346 28.909 44.7828 31.4972 43.7107C34.0855 42.6386 36.4373 41.0672 38.4183 39.0862C40.3993 37.1053 41.9707 34.7535 43.0428 32.1652C44.1149 29.5769 44.6667 26.8028 44.6667 24.0013C44.6667 21.1998 44.1149 18.4257 43.0428 15.8374C41.9707 13.2491 40.3993 10.8973 38.4183 8.91636C36.4373 6.93537 34.0855 5.36397 31.4972 4.29187C28.909 3.21977 26.1349 2.66797 23.3333 2.66797C20.5318 2.66797 17.7577 3.21977 15.1694 4.29187C12.5811 5.36397 10.2294 6.93537 8.24839 8.91636C6.26741 10.8973 4.696 13.2491 3.6239 15.8374C2.5518 18.4257 2 21.1998 2 24.0013Z"
										stroke="white"
										strokeWidth="4"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M18.5938 24.0025C18.5938 25.2598 19.0932 26.4656 19.9823 27.3547C20.8713 28.2437 22.0772 28.7432 23.3345 28.7432M18.5938 24.0025C18.5938 22.7451 19.0932 21.5393 19.9823 20.6503C20.8713 19.7612 22.0772 19.2617 23.3345 19.2617C24.5918 19.2617 25.7976 19.7612 26.6867 20.6503C27.5758 21.5393 28.0752 22.7451 28.0752 24.0025M18.5938 24.0025L2.59375 19.2617M23.3345 28.7432C24.5918 28.7432 25.7976 28.2437 26.6867 27.3547C27.5758 26.4656 28.0752 25.2598 28.0752 24.0025M23.3345 28.7432V45.3358M28.0752 24.0025L44.0752 19.2617"
										stroke="white"
										strokeWidth="4"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
								<span className="section_1_footer_title">
									Driver Partners
								</span>
								<CountUp
									start={loaded ? 0 : -1}
									end={1210 + 5 * valueMultiplier}
									duration={2}
									useIndianSeparators={true}
									className="section_1_footer_count"
									enableScrollSpy={loaded}
									scrollSpyOnce={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="section_2">
					<div className="title">Why choose Us</div>
					<div className="desc">
						India's First EV Logistics Powerhouse
					</div>
					<div className="section_2_content">
						<div className="section_2_content_text">
							<p className="section_2_content_text_head">
								{whyChooseUsTexts[selectedWhyChooseUs].title}
							</p>
							<p className="section_2_content_text_body">
								{whyChooseUsTexts[selectedWhyChooseUs].desc}
							</p>
						</div>
						<div className="section_2_content_wheel">
							<svg
								className="show_icon"
								xmlns="http://www.w3.org/2000/svg"
								width="64"
								height="72"
								viewBox="0 0 64 72"
								fill="none"
							>
								<path
									d="M4.33899 43.5953C-1.44633 40.2196 -1.44633 31.7804 4.33898 28.4047L50.983 1.18809C56.7684 -2.18762 64 2.03201 64 8.78341L64 63.2166C64 69.968 56.7684 74.1876 50.9831 70.8119L4.33899 43.5953Z"
									fill="url(#paint0_linear_694_501)"
									fillOpacity="0.5"
								/>
								<defs>
									<linearGradient
										id="paint0_linear_694_501"
										x1="65.1519"
										y1="70.4122"
										x2="-3.40294"
										y2="6.85564"
										gradientUnits="userSpaceOnUse"
									>
										<stop
											offset="2.66389e-07"
											stopColor="#0092BD"
										/>
										<stop offset="1" stopColor="#00C67A" />
									</linearGradient>
								</defs>
							</svg>
							<WhyChooseUsChart
								width={250}
								height={250}
								onArcClick={(i) => setSelectedWhyChooseUs(i)}
							/>
						</div>
					</div>
				</div>
				<div className="section_3_sibling">
					<InView>
						{({ inView, ref }) => (
							<h3
								ref={ref}
								className={`client_heading ${
									inView ? "animate_homepage" : ""
								}`}
							>
								CLIENTS
							</h3>
						)}
					</InView>
					<BrandsCard />
				</div>
				<div className="section_3">
					<div className="title">case studies</div>
					<div className="desc">
						Data-driven insights and cutting-edge technology
					</div>
					<div className="case_studies">
						<CaseStudy
							number={"01"}
							title={"ZEVO's Partnership with Zomato Hyperpure"}
							desc={
								"Since inception in April 2023, ZEVO has forged an impactful collaboration with Zomato Hyperpure, reshaping the delivery landscape by prioritizing sustainability and efficiency. Specializing in grocery deliveries, our operations have expanded to encompass pivotal locations like Chimbli in Pune, Mundka in Delhi, and Noida. This pioneering alliance, built on shared values, marks a significant stride towards revolutionizing the delivery sector."
							}
							logo={hyperpure.src}
							case_study_logo_light={hyperpure_light.src}
							vehicles={"17"}
							deliveries={75582 + 1250 * valueMultiplier1}
							kms={405108 + 1500 * valueMultiplier1}
							co2_saved={"10318 Kgs"}
						/>
						<CaseStudy
							number={"02"}
							title={"ZEVO's Alliance with Big Basket"}
							desc={
								"Big Basket and ZEVO join forces to revolutionize the grocery delivery experience across multiple locations. From the bustling streets of Bhosari and Hinjewadi in Pune to the vibrant markets of Sitapura in Rajasthan and Ghazipur in Delhi, this collaboration spans diverse regions like Pisoli, Mundhwa, Ubalenagar, Tragad, Sarkhej, Wazirabad, and Ohkla. Together, we are redefining convenience, ensuring fresh groceries reach your doorstep, no matter where you are."
							}
							logo={bigbasket.src}
							case_study_logo_light={bigbasket_light.src}
							vehicles={"75"}
							deliveries={215951 + 1250 * valueMultiplier1}
							kms={719838 + 1500 * valueMultiplier1}
							co2_saved={"18427 Kgs"}
						/>
						<CaseStudy
							number={"03"}
							title={"ZEVO and Flipkart's Synergy"}
							desc={
								"An alliance between Flipkart and ZEVO, a strategic partnership designed to redefine grocery delivery covers diverse locations like Zirakpur, Tragad, Panipat, Sonipat, Faridabad, Mayur Vihar, Badarpur, Bamnoli, Ohkla, and Ussmanpur, this collaboration ensures swift, efficient, and reliable delivery of essential goods. Together, they're transforming the landscape of grocery logistics, bringing convenience right to your doorstep."
							}
							logo={flipkart.src}
							case_study_logo_light={flipkart_light.src}
							vehicles={"300"}
							deliveries={215620 + 1250 * valueMultiplier1}
							kms={219240 + 1500 * valueMultiplier1}
							co2_saved={"11055.6 Kgs"}
						/>
						<CaseStudy
							number={"04"}
							title={"ZEVO Joins Hands With Shadowfax "}
							desc={
								"In a groundbreaking collaboration, ZEVO and Shadowfax have joined forces to transform the landscape of grocery deliveries, focusing on the Lado Sarai and Jhotwara areas. Notably, this dynamic partnership and their commitment to sustainability shines through as they're setting a new standard for eco-conscious logistics in the industry."
							}
							logo={shadowfox.src}
							case_study_logo_light={shadowfox_light.src}
							vehicles={"18"}
							deliveries={32392 + 1250 * valueMultiplier1}
							kms={64785 + 1500 * valueMultiplier1}
							co2_saved={"1656.77 Kgs"}
						/>
						<CaseStudy
							number={"05"}
							title={
								"ZEVO's Transformative Collaboration with HOLISOL"
							}
							desc={
								"Focused on serving the thriving communities of Ghitorni, Swaroop Nagar, and Noida, this ZEVO proudly announces its collaboration with HOLISOL and has proven to be a game-changer in sustainable transportation. More importantly, the collaboration has resulted in zero emissions, underlining the commitment of both entities to environmental stewardship."
							}
							logo={holisol.src}
							case_study_logo_light={holisol_light.src}
							vehicles={"10"}
							deliveries={10496 + 1250 * valueMultiplier1}
							kms={25194 + 1500 * valueMultiplier1}
							co2_saved={"644.19 Kgs"}
						/>
						<CaseStudy
							number={"06"}
							title={"ZEVO's Impactful Collaboration with Porter"}
							desc={
								"ZEVO, a trailblazer in sustainable solutions, has forged a groundbreaking collaboration with Porter to redefine the way shipments are processed. This strategic partnership not only demonstrates ZEVO's commitment to efficient goods delivery but also underscores their dedication to the environment."
							}
							logo={porter.src}
							case_study_logo_light={porter_light.src}
							vehicles={"40"}
							deliveries={18115 + 1020 * valueMultiplier1}
							kms={30700 + 3500 * valueMultiplier1}
							co2_saved={"368.54 Kgs"}
						/>
					</div>
				</div>
				<div className="section_see_us_action">
					<div className="title">See us action</div>
					<div className="video_container">
						<video
							ref={videoRefSeeUsAction}
							width="800"
							height="450"
							onClick={toggleVideo}
							onEnded={restartVideo}
						>
							<source
								src="/video/about-us-montage.mp4"
								type="video/mp4"
							/>
						</video>
						<Image
							ref={videoPlayBtnRef}
							className="play_btn"
							src={playBtn}
							width={50}
							height={0}
							alt="icon"
							onClick={toggleVideo}
						/>
					</div>
				</div>
				<div className="section_4">
					<img
						className="background_shapes wave1"
						src={bg_shape.src}
						alt="shape icon"
					/>
					<img
						className="background_shapes wave2"
						src={bg_shape.src}
						alt="shape icon"
					/>
					<div className="title">What we offer</div>
					<div className="desc">
						Reimagine Your Supply Chain with ZEVO
					</div>
					<div className="what_we_offers">
						<WhatWeOffer
							bg={"grey"}
							img={wwo1.src}
							title={"Reliable EV Drivers"}
							desc={
								"Trust our team of dedicated and experienced drivers with comprehensive training guarantee smooth and sustainable EV deliveries."
							}
							pos={"left"}
							absolute={false}
						/>
						<WhatWeOffer
							bg={"green"}
							img={wwo2.src}
							title={"Smart Electric Vehicles"}
							desc={
								"Explore from our diverse fleet of electric vehicles (from cargo bikes to last-mile vans) that plug seamlessly into your existing operations and ensure smooth integration with maximized efficiency."
							}
							pos={"right"}
						/>
						<WhatWeOffer
							bg={"blue"}
							img={wwo3.src}
							title={
								"Temperature-Controlled Refrigerated Delivery"
							}
							desc={
								"Transform your last-mile pharma, seafood, dairy, and FMCG delivery with our smart electric refrigerated vehicles for temperature-sensitive goods."
							}
							pos={"left"}
						/>
						<WhatWeOffer
							bg={"green"}
							img={wwo4.src}
							title={"Micro Storage Facilities"}
							desc={
								"Choose our strategically placed micro storage facilities to reduce the carbon footprint of your company."
							}
							pos={"right"}
							absolute={false}
						/>
						<WhatWeOffer
							bg={"grey"}
							img={wwo5.src}
							title={"Network of Charging Stations"}
							desc={
								"Choose to charge your electric vehicle fleet with our strategically installed charging and battery swapping stations."
							}
							pos={"left"}
							absolute={false}
						/>
					</div>
				</div>
				<div className="section_5">
					<img
						className="background_shapes bg_tri"
						src={bg_tri.src}
						alt="bg_tri"
					/>
					<div className="title">How to associate with us</div>
					<div className="desc">
						3 Simple Steps to Power Up Your Business with ZEVO's
						Electric Mobility Revolution
					</div>
					<Swiper
						autoplay={{
							delay: 3000,
							pauseOnMouseEnter: true,
						}}
						initialSlide={0}
						onSwiper={setSwiper}
						effect="cards"
						grabCursor={true}
						centeredSlides={true}
						slidesPerView="auto"
						cardsEffect={{
							rotate: false,
							stretch: stretchValue,
							depth: 100,
							modifier: 0.1,
							perSlideOffset: 18,
							perSlideRotate: 0,
							slideShadows: true,
						}}
						className="associate_swiper_cards"
						onInit={function () {
							for (let i = 0; i < this.slides.length; i++) {
								if (i !== this.activeIndex) {
									this.slides[i].classList.add(
										"swiper-slide-inactive",
									);
								}
							}
							if (this.isBeginning) {
								document.querySelector(
									".associate_swiper_cards_nav_prev",
								).style.display = "none";
							}
						}}
						onSlideChange={function (e) {
							setAssociateActiveSlide(e.activeIndex);
							for (let i = 0; i < this.slides.length; i++) {
								if (i !== this.activeIndex) {
									this.slides[i].classList.add(
										"swiper-slide-inactive",
									);
								} else {
									this.slides[i].classList.remove(
										"swiper-slide-inactive",
									);
								}
							}
							document.querySelector(
								".associate_swiper_cards_nav_prev",
							).style.display = this.isBeginning
								? "none"
								: "flex";
							document.querySelector(
								".associate_swiper_cards_nav_next",
							).style.display = this.isEnd ? "none" : "flex";
						}}
					>
						<SwiperSlide className="associate_swiper_card">
							<div className="swiper_card_img">
								<img src={associate1.src} alt="associate" />
							</div>
							<div className="swiper_card_div">
								<p className="swiper_card_title">
									Begin Your Journey with ZEVO
								</p>
								<p className="swiper_card_desc">
									Register on our user-friendly platform and
									tell us about your specific needs. We'll
									tailor our solutions to fit your unique
									business requirements, ensuring a
									personalized experience right from the
									start.
								</p>
							</div>
						</SwiperSlide>
						<SwiperSlide className="associate_swiper_card">
							<div className="swiper_card_img">
								<img src={associate2.src} alt="associate" />
							</div>
							<div className="swiper_card_div">
								<p className="swiper_card_title">
									Seamless Integration and Expert Guidance
								</p>
								<p className="swiper_card_desc">
									We'll seamlessly integrate our technology
									with your existing systems and provide
									comprehensive training. This ensures you're
									fully equipped to utilize our services for
									optimal results.
								</p>
							</div>
						</SwiperSlide>
						<SwiperSlide className="associate_swiper_card">
							<div className="swiper_card_img">
								<img src={associate3.src} alt="associate" />
							</div>
							<div className="swiper_card_div">
								<p className="swiper_card_title">
									Continuous Support and Insightful Analytics
								</p>
								<p className="swiper_card_desc">
									At ZEVO, we're committed to your success.
									Our ongoing support and real-time analytics
									keep you informed and in control, helping
									you make strategic decisions for better
									fleet management and operational efficiency.
								</p>
							</div>
						</SwiperSlide>
					</Swiper>
					<div className="associate_swiper_cards_nav">
						<div
							className="associate_swiper_cards_nav_prev"
							onClick={() => {
								swiper.slideTo(associateActiveSlide - 1);
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12.0727 2.6941C11.7118 3.0726 11.7264 3.67165 12.1053 4.03212L19.4897 11.0577H2.14815C1.62493 11.0577 1.20078 11.4815 1.20078 12.0041C1.20078 12.5268 1.62493 12.9506 2.14815 12.9506H19.4818L12.1053 19.9687C11.7264 20.3291 11.7118 20.9282 12.0727 21.3067C12.4335 21.6852 13.0332 21.6998 13.412 21.3393L22.4585 12.7324C22.6676 12.5588 22.8008 12.297 22.8008 12.0041L22.8008 12.0004C22.8009 11.7502 22.7024 11.5005 22.5068 11.3143L13.412 2.66147C13.0332 2.301 12.4335 2.31561 12.0727 2.6941Z"
									fill="white"
								/>
							</svg>
						</div>
						<div
							className="associate_swiper_cards_nav_next"
							onClick={() => {
								swiper.slideTo(associateActiveSlide + 1);
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12.0727 2.6941C11.7118 3.0726 11.7264 3.67165 12.1053 4.03212L19.4897 11.0577H2.14815C1.62493 11.0577 1.20078 11.4815 1.20078 12.0041C1.20078 12.5268 1.62493 12.9506 2.14815 12.9506H19.4818L12.1053 19.9687C11.7264 20.3291 11.7118 20.9282 12.0727 21.3067C12.4335 21.6852 13.0332 21.6998 13.412 21.3393L22.4585 12.7324C22.6676 12.5588 22.8008 12.297 22.8008 12.0041L22.8008 12.0004C22.8009 11.7502 22.7024 11.5005 22.5068 11.3143L13.412 2.66147C13.0332 2.301 12.4335 2.31561 12.0727 2.6941Z"
									fill="white"
								/>
							</svg>
						</div>
					</div>
				</div>
				<div className="section_6">
					<div className="title">our founders</div>
					<div className="founders_div">
						<div className="founders_div_desc_div">
							<div className="founders_div_title_div">
								<div className="founders_div_title">
									Aditya Singh Ratnu, CEO
								</div>
								<div className="founders_div_title_linkedin">
									<Link
										rel="canonical"
										href="https://www.linkedin.com/in/aditya-singh-ratnu-66727016b/"
									>
										<svg
											width="32"
											height="33"
											viewBox="0 0 32 33"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g
												id="linkedin-linked-in-svgrepo-com 1"
												clipPath="url(#clip0_694_869)"
											>
												<path
													id="Vector"
													d="M23 0.5H9C4 0.5 0 4.5 0 9.5V23.5C0 28.5 4 32.5 9 32.5H23C28 32.5 32 28.5 32 23.5V9.5C32 4.5 28 0.5 23 0.5Z"
													fill="black"
												/>
												<path
													id="Vector_2"
													d="M12 25.5C12 26.1 11.6 26.5 11 26.5H7C6.4 26.5 6 26.1 6 25.5V13.5C6 12.9 6.4 12.5 7 12.5H11C11.6 12.5 12 12.9 12 13.5V25.5ZM9 11.5C7.3 11.5 6 10.2 6 8.5C6 6.8 7.3 5.5 9 5.5C10.7 5.5 12 6.8 12 8.5C12 10.2 10.7 11.5 9 11.5ZM26 25.5C26 26.1 25.6 26.5 25 26.5H22C21.4 26.5 21 26.1 21 25.5V22V21V19C21 18.2 20.3 17.5 19.5 17.5C18.7 17.5 18 18.2 18 19V21V22V25.5C18 26.1 17.6 26.5 17 26.5H14C13.4 26.5 13 26.1 13 25.5V13.5C13 12.9 13.4 12.5 14 12.5H18C18.3 12.5 18.5 12.6 18.7 12.8C19.3 12.6 19.9 12.5 20.5 12.5C23.5 12.5 26 15 26 18V25.5Z"
													fill="white"
												/>
											</g>
											<defs>
												<clipPath id="clip0_694_869">
													<rect
														width="32"
														height="32"
														fill="white"
														transform="translate(0 0.5)"
													/>
												</clipPath>
											</defs>
										</svg>
									</Link>
								</div>
							</div>
							<div className="founders_div_desc">
								For me, ZEVO is more than just a company; it's a
								movement toward zero-emission vehicle operations
								that redefine the way businesses approach
								logistics. The pressing challenges of
								sustainable mobility for businesses resonated
								deeply with me. The inception of ZEVO in 2022
								wasn't just a business venture; it was a
								conscious endeavor born out of rigorous research
								and a steadfast commitment to address critical
								gaps in the industry.
							</div>
						</div>
						<div className="founders_div_img">
							<img src={ceo.src} alt="ceo" />
						</div>
					</div>
					<div className="founders_div left">
						<div className="founders_div_img">
							<img src={coo.src} alt="coo" />
						</div>
						<div
							className="founders_div_desc_div"
							style={{ zIndex: 10 }}
						>
							<div className="founders_div_title_div">
								<div className="founders_div_title">
									Dhruv Bhatia, COO
								</div>
								<div className="founders_div_title_linkedin">
									<Link
										rel="canonical"
										href="https://www.linkedin.com/in/dhruv-bhatia-043945b7/"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="32"
											height="32"
											viewBox="0 0 32 32"
											fill="none"
										>
											<g clipPath="url(#clip0_694_879)">
												<path
													d="M9.00781 0.5H23.0078C27.7317 0.5 31.5078 4.27614 31.5078 9V23C31.5078 27.7239 27.7317 31.5 23.0078 31.5H9.00781C4.28396 31.5 0.507812 27.7239 0.507812 23V9C0.507812 4.27614 4.28396 0.5 9.00781 0.5Z"
													stroke="white"
												/>
												<path
													d="M12.0078 25C12.0078 25.6 11.6078 26 11.0078 26H7.00781C6.40781 26 6.00781 25.6 6.00781 25V13C6.00781 12.4 6.40781 12 7.00781 12H11.0078C11.6078 12 12.0078 12.4 12.0078 13V25ZM9.00781 11C7.30781 11 6.00781 9.7 6.00781 8C6.00781 6.3 7.30781 5 9.00781 5C10.7078 5 12.0078 6.3 12.0078 8C12.0078 9.7 10.7078 11 9.00781 11ZM26.0078 25C26.0078 25.6 25.6078 26 25.0078 26H22.0078C21.4078 26 21.0078 25.6 21.0078 25V21.5V20.5V18.5C21.0078 17.7 20.3078 17 19.5078 17C18.7078 17 18.0078 17.7 18.0078 18.5V20.5V21.5V25C18.0078 25.6 17.6078 26 17.0078 26H14.0078C13.4078 26 13.0078 25.6 13.0078 25V13C13.0078 12.4 13.4078 12 14.0078 12H18.0078C18.3078 12 18.5078 12.1 18.7078 12.3C19.3078 12.1 19.9078 12 20.5078 12C23.5078 12 26.0078 14.5 26.0078 17.5V25Z"
													fill="white"
												/>
											</g>
											<defs>
												<clipPath id="clip0_694_879">
													<rect
														width="32"
														height="32"
														fill="white"
														transform="translate(0.0078125)"
													/>
												</clipPath>
											</defs>
										</svg>
									</Link>
								</div>
							</div>
							<div className="founders_div_desc">
								From the moment I joined this remarkable venture
								in 2022, my passion and commitment to
								transforming the landscape of sustainable
								mobility for businesses were abundantly evident.
								The lack of sustainable mobility solutions,
								underutilized infrastructure for micro
								warehousing, and inefficient cold chain
								logistics made my vision crystal clear: to
								create a positive impact on the environment
								while revolutionizing the way businesses operate
								through ZEVO.
							</div>
						</div>
					</div>
					<div
						className="meet_the_team"
						onClick={() => {
							router.push("/about-us");
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
						>
							<path
								d="M11 11.5V5.5H13V11.5H19V13.5H13V19.5H11V13.5H5V11.5H11Z"
								fill="black"
							/>
						</svg>
						<span>Meet the team</span>
					</div>
				</div>
				<div className="section_7">
					<div className="title">featured articles</div>
					<Swiper
						ref={featuredArticleRef}
						slidesPerView={isMobile ? 1 : isTablet ? 2 : 4}
						spaceBetween={30}
						className="featured_article_swiper"
						onSlideChange={onFeaturedArticleSlideChange}
					>
						<SwiperSlide>
							<FeaturedArticle
								url="https://www.entrepreneur.com/en-in/news-and-trends/zevo-secures-undisclosed-amount-of-funding-from-agility/457895"
								img={article1.src}
								date="August 23, 2023"
								desc="ZEVO Secures Undisclosed Amount Of Funding From Agility Ventures"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<FeaturedArticle
								url="https://startupstorymedia.com/insights-zevo-secures-funding-from-agility-ventures-to-drive-sustainable-supply-chain-solutions-expansion/"
								img={article2.src}
								date="August 23, 2023"
								desc="ZEVO Secures Funding from Agility Ventures to Drive Sustainable Supply Chain Solutions Expansion"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<FeaturedArticle
								url="https://startupnews.fyi/2023/08/23/zevo-secures-funding-from-agility-ventures-to-accelerate-growth-in-sustainable-supply-chain-solutions/"
								img={article3.src}
								date="August 23, 2023"
								desc="ZEVO Secures Funding from Agility Ventures to Accelerate Growth in Sustainable Supply Chain Solutions"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<FeaturedArticle
								url="https://www.vccircle.com/superbottomstwo-others-raise-early-stage-funding"
								img={article4.src}
								date="August 23, 2023"
								desc="SuperBottoms, two others raise early-stage funding"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<FeaturedArticle
								url="https://bwdisrupt.businessworld.in/amp/article/EV-Supply-Chain-Warehousing-Startup-Zevo-Secures-Funding-From-Agility-Ventures/24-08-2023-488822/"
								img={article5.src}
								date="August 23, 2023"
								desc="EV Supply Chain, Warehousing Startup ZEVO Secures Funding From Agility Ventures"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<FeaturedArticle
								url="https://opportunityindia.com/article/zevo-secures-funding-from-agility-ventures-to-boost-growth-35464"
								img={article6.src}
								date="August 23, 2023"
								desc="ZEVO Secures Funding from Agility Ventures to Boost Growth"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<FeaturedArticle
								url="https://businessnewsthisweek.com/business/zevo-secures-funding-from-agility-ventures-to-accelerate-growth-in-sustainable-supply-chain-solutions/"
								img={article7.src}
								date="August 23, 2023"
								desc="ZEVO Secures Funding from Agility Ventures to Accelerate Growth in Sustainable Supply Chain Solutions"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<FeaturedArticle
								url="https://newspatrolling.com/zevo-secures-funding-from-agility-ventures-to-accelerate-growth-in-sustainable-supply-chain-solutions/"
								img={article8.src}
								date="August 23, 2023"
								desc="ZEVO Secures Funding from Agility Ventures to Accelerate Growth in Sustainable Supply Chain Solutions"
							/>
						</SwiperSlide>
					</Swiper>
					<div className="featured_article_btn_div">
						<button
							className="featured_article_btn disabled"
							onClick={() =>
								featuredArticleRef.current.swiper.slidePrev()
							}
							disabled={isFeaturedArticleBeginning}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="#8399A3"
							>
								<path d="M11 11.5V5.5H13V11.5H19V13.5H13V19.5H11V13.5H5V11.5H11Z" />
							</svg>
							<span>Previous</span>
						</button>
						<button
							className="featured_article_btn"
							onClick={() =>
								featuredArticleRef.current.swiper.slideNext()
							}
							disabled={isFeaturedArticleEnd}
						>
							<span>Next</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="#152A31"
							>
								<path d="M11 11.5V5.5H13V11.5H19V13.5H13V19.5H11V13.5H5V11.5H11Z" />
							</svg>
						</button>
					</div>
				</div>
				<div
					className="section_8"
					style={{ background: "rgb(242, 246, 247)" }}
				>
					<video
						ref={videoRef}
						autoPlay
						muted
						loop
						className="section_8_video"
					>
						<source src={url} type="video/webm" />
					</video>
				</div>
			</div>
			<Footer />
		</>
	);
}
