"use client";
import "./style.css";

const CaseStudy = ({
	number,
	title,
	desc,
	logo,
	case_study_logo_light,
	vehicles,
	deliveries,
	kms,
	co2_saved,
}) => {
	const formatNumberWithCommas = (number) => {
		return Math.floor(number).toLocaleString("en-IN");
	};

	return (
		<div className="case_study_homepage">
			<div className="case_study_body">
				<div className="number">{number}</div>
				<img
					className="case_study_logo_light"
					src={case_study_logo_light}
					alt="case_study_logo_light"
				/>
				<div className="logo">
					<img src={logo} alt="logo" />
				</div>
				<div className="case_study_title">{title}</div>
				<div className="case_study_desc">{desc}</div>
			</div>
			<div className="case_study_footers">
				<table>
					<tbody>
						<tr>
							<td>
								<div className="case_study_footer">
									<p className="case_study_footer_title">
										Vehicles
									</p>
									<p className="case_study_footer_desc">
										{formatNumberWithCommas(vehicles)}
									</p>
								</div>
							</td>
							<td>
								<div className="case_study_footer">
									<p className="case_study_footer_title">
										Deliveries
									</p>
									<p className="case_study_footer_desc">
										{formatNumberWithCommas(deliveries)}
									</p>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div className="case_study_footer">
									<p className="case_study_footer_title">
										Kms
									</p>
									<p className="case_study_footer_desc">
										{formatNumberWithCommas(kms)}
									</p>
								</div>
							</td>
							<td>
								<div className="case_study_footer">
									<p className="case_study_footer_title">
										CO2 saved
									</p>
									<p className="case_study_footer_desc">
										{co2_saved}
									</p>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default CaseStudy;
