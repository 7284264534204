"use client";
import "./style.css";

const FeaturedArticle = ({ url, img, date, desc }) => {
	const handleOpenNewTab = () => {
		window.open(url, "_blank");
	};

	return (
		<div className="featured_article_swiper_div" onClick={handleOpenNewTab}>
			<div className="featured_article_img">
				<img src={img} alt="featured_article_img" />
			</div>
			<div className="featured_article_date">{date}</div>
			<div className="featured_article_desc">{desc}</div>
			<div className="featured_article_bottom_border"></div>
		</div>
	);
};

export default FeaturedArticle;
