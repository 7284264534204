"use client";
import { useEffect, useRef, useState } from "react";
import "./style.css";

const WhatWeOffer = ({ bg, img, title, desc, pos, absolute }) => {
	const [inView, setInView] = useState(false);
	const ref = useRef(null);
	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) setInView(entry.isIntersecting);
			},
			{ threshold: 0.1 },
		);

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [ref]);
	return (
		<div
			ref={ref}
			className={`what_we_offer_homepage pos_${pos || "left"} ${
				inView ? "in-view" : ""
			}`}
		>
			<div
				className={`what_we_offer_homepage_img ${
					bg?.length ? bg : ""
				} pos_left ${absolute === false ? "no_absolute" : ""}`}
			>
				<img src={img} alt="what_we_offer_homepage_img" />
			</div>
			<div className="what_we_offer_homepage_title_div">
				<p className="what_we_offer_homepage_title">{title}</p>
				<p className="what_we_offer_homepage_desc">{desc}</p>
			</div>
			<div
				className={`what_we_offer_homepage_img ${
					bg?.length ? bg : ""
				} pos_right ${absolute === false ? "no_absolute" : ""}`}
			>
				<img src={img} alt="what_we_offer_homepage_img" />
			</div>
		</div>
	);
};

export default WhatWeOffer;
